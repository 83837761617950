.page-collaborator {
  .collaborator-heading {
    h1 {
      font-size: 36px;
      line-height: 1.2;
      font-weight: 600;
      margin-bottom: 10px;
      @include media-breakpoint-up(md) {
        font-size: 48px;
      }
    }
  }

  .collaborator-job {
    margin-bottom: 40px;
    font-weight: 600;
    font-family: $font-family-heading;
  }

  .collaborator-description {
    width: 80%;

    p {

    }
  }

  .gallery {
    display: grid;
    gap: $grid-gutter-width;

    .gallery-item {
      .gallery-image {
        img {
          vertical-align: bottom;
        }
      }

      .gallery-caption {
        margin-top: 5px;
        font-size: 16px;

        strong {
          font-weight: 600;
        }

        span {
          color: var(--color-base-text-2);
          font-weight: 400;
        }
      }
    }
  }

  .gallery-1 {
    grid-template-columns: 1;
  }

  .gallery-2 {
    grid-template-columns: 1fr 1fr;

    .gallery-sizer {
    }

    .gallery-item {
      margin-bottom: $grid-gutter-width;
    }
  }

  .gallery-3 {
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 400px;

    .gallery-item {
      overflow: hidden;
    }
  }

  .content {
    width: 90%;
  }

  .collaborator-image {
    img {
      max-width: 100%;
      height: auto;
    }
  }

  .collaborator-stats {
    margin-top: 50px;
  }

  .collaborator-date,
  .collaborator-role,
  .collaborator-client {
    margin-bottom: 10px;
    font-size: 18px;
    font-family: $font-family-heading;

    strong {
      display: inline-block;
      font-weight: 600;
      width: 70px;
    }

    span {
      display: inline-block;
      font-weight: 400;
    }
  }
}
