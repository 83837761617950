// Lightmode colors
$primary: #1d1d1d;
$primary-2: #1d1d1d;
$primary-text: #fdfcf9;
$base-bg: #fcfcfd;
$base-bg-2: #EAEBEC;
$base-bg-3: #e0e2e5;
$base-bg-4: #f0f0f1;
$base-text: #0d042b;
$base-text-2: #3b364c;
$logo-text:  #111827;

// Darkmode colors
$primary-dark: #0d042b;
$primary-2-dark: #0d042b;
$primary-text-dark: #fdfcf9;
$base-bg-dark: #1C222E;
$base-bg-2-dark: #adb5bd;
$base-bg-3-dark: #8B8A81;
$base-text-dark: #FFFFFF;
$base-text-2-dark: #e9ecef;
$logo-text-dark: #F4F4F5;

// Common Colors
$secondary: #DD633E;

$zs-colors: (
    "primary": $primary,
    "secondary": $secondary,
    "primary-2": $primary-2,
    "primary-text": $primary-text,
    "base-bg": $base-bg,
    "base-bg-2": $base-bg-2,
    "base-bg-3": $base-bg-3,
    "base-bg-4": $base-bg-4,
    "base-text": $base-text,
    "base-text-2": $base-text-2,
    "logo-text": $logo-text,
    "primary-dark": $primary-dark,
    "primary-2-dark": $primary-2-dark,
    "primary-text-dark": $primary-text-dark,
    "base-bg-dark": $base-bg-dark,
    "base-bg-2-dark": $base-bg-2-dark,
    "base-bg-3-dark": $base-bg-3-dark,
    "base-text-dark": $base-text-dark,
    "base-text-2-dark": $base-text-2-dark,
    "logo-text-dark": $logo-text-dark
);

$zs-colors-css-vars: ();
@each $color, $value in $zs-colors {
  $zs-colors-css-vars: map-merge(
    $zs-colors-css-vars,
    (#{$color}: var(--color-#{$color}))
  )
}

$font-family-heading: source-serif-pro, Georgia, Cambria, "Times New Roman", Times, serif;
$font-family-base: source-serif-pro, Georgia, Cambria, "Times New Roman", Times, serif;
$font-family-monospace: Roboto Mono, SFMono-Regular, Menlo, Monaco, Consolas, "Courier New", monospace;
$font-family-logo: source-serif-pro, -apple-system, "Helvetica Neue", Arial, sans-serif;

:root{
  @each $color, $value in $zs-colors {
    --color-#{$color}: #{$value};
  }
}

html[data-mode='light'] {
  @each $color, $value in $zs-colors {
      --color-#{$color}: #{$value};
  }
}

@import 'style';
