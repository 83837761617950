.bottom {
  padding-top: calc($section-gutter / 2);
  padding-bottom: calc($section-gutter / 2);
  font-size: 13px;
  color: var(--color-base-text-dark);

  .copyright {
    padding: 3px 0;
    @include media-breakpoint-up(md) {
      display: inline-block;
      padding: 0;
    }

    font-weight: 600;
  }

  .menu-bottom {
    @include media-breakpoint-up(md) {
      display: inline-block;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        margin: 0;
        padding: 0;
        display: block;
        @include media-breakpoint-up(md) {
          display: inline-block;
        }

        a {
          display: inline-block;
          color: var(--color-base-text-dark);
          text-decoration: underline;
          padding: 3px 0;
          @include media-breakpoint-up(md) {
            padding: 6px;
          }

          &:hover {
            text-decoration: underline;
          }

          &:first-of-type {
            padding-left: 0px;
          }
        }

      }
    }
  }
}
