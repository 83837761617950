.cardabelle-left-red {
  background-image: url("/assets/images/bg/texture_white.jpeg");
  background-size: cover;
}

.cardabelle-left {
  background-image: url("/assets/images/bg/lucie-simon-fond-orange-cardabelle.jpg");
  background-size: cover;
}

.team-portrait {
  background: var(--color-secondary);
  border: 1px solid var(--color-secondary);
}

.white-paper {
  background-image: url("/assets/images/bg/bw2.jpg");
}

.paper {
  background-image: url("/assets/images/bg/paper.jpg");
  background-size: cover;
}

.footer {
  background: var(--color-secondary);
}

.paper-white {
  background: url("/assets/images/bg/texture-paper.jpg");
}

.bg-grid {
  background: url("/assets/images/bg/texture-home2.jpg");
  background-size: cover;
  background-position: bottom;
}

.sub-hero {
  background: var(--color-secondary);
  background-repeat: repeat;
}

.red-line {
  height: 3px;
  width: 30px;
  background: var(--color-secondary);
}

.cardabelle-white-left {
  background: var(--color-base-bg-2) url("/assets/images/logo/just-cardabelle.svg");
  background-repeat: no-repeat;
  background-size: 500px auto;
  background-position: -250px 50%;
  display: flex;
  min-width: 400px;
  min-height: 250px;
  @include media-breakpoint-down(lg) {
    min-width: 100%;
    min-height: 300px;
  }
}

.cardabelle-white-right {
  background: var(--color-base-bg) url("/assets/images/logo/just-cardabelle.svg");
  background-repeat: no-repeat;
  background-size: 500px auto;
  background-position: 350px 100%;
}

.absolute-cardabelle {
  position: absolute;
  left: 35px;
  bottom: 20px;
  width: 100px;
}

.shadow-top-light {
  box-shadow: 0px -60px 60px rgba(0, 0, 0, .01);
}
