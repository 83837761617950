.section.hero-section {
  padding-top: 120px !important;
  padding-bottom: 60px !important;
}

.hero-img {
  margin: 0 auto;
  width: 1320px;
}

.home-row {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  -ms-grid-columns: 0.65fr 1fr;
  grid-template-columns: 0.65fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;

  @include media-breakpoint-down(lg) {
    grid-template-columns: 1fr;
  }
}

.large-title-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 96px;
  padding-bottom: 96px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  @include media-breakpoint-down(lg) {
    padding: 72px 24px;
  }
  @include media-breakpoint-down(md) {
    padding-top: 48px;
    padding-bottom: 48px;
  }
}

.large-title-row {
  grid-template-columns: 1fr
}

.content-with-medium {
  max-width: 500px;
}

.home-hero .bg-grid {
  height: 220px;
  background-size: cover;
  background-attachment: fixed;
}

.home-hero .bg-grid:nth-child(1) {
  grid-column: 1 / 3;
}

.home-hero .bg-grid:nth-child(4), {
  grid-column: 1 / 2;
  box-shadow: inset 0 0 0 100vmax rgba(0, 0, 0, .2)
}

.home-hero .bg-grid:nth-child(5) {
  grid-column: 2 / 5;
}


.post-grid {
  display: -ms-grid;
  display: grid;
  grid-auto-flow: row;
  grid-auto-columns: 1fr;
  grid-column-gap: 12px;
  grid-row-gap: 40px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;

  @include media-breakpoint-down(lg) {
    max-width: 570px;
    margin-right: auto;
    margin-left: auto;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

.card.blog-card {
  height: 100%;
}

.card {
  display: flex;
  flex-direction: column;
}

.card-body {
  display: flex;
  padding: 36px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 0 auto;
  @include media-breakpoint-down(lg) {
    padding-left: 24px;
    padding-right: 24px;

    .contact-email, .contact-phone {
      font-size: 18px;
    }
  }
}


.grid-vertical {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.w-layout-grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
}

.grid-halves {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  @include media-breakpoint-down(lg) {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

.grid-1-3 {
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: auto;
  @include media-breakpoint-down(lg) {
    grid-template-columns: 2fr;
  }
}

.vertical-expertises-list-overlap {
  margin-top: -140px;
}

.vertical-expertises-list {
  display: grid;
  margin-right: auto;
  margin-left: auto;
  grid-auto-columns: 1fr;
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  max-width: 1024px;
  box-shadow: 0px -30px 30px rgba(0, 0, 0, .01);
}

.expertise-item {
  display: flex;
  @include media-breakpoint-down(lg) {
    flex-direction: column-reverse;
  }

  &-noreverse {
    @include media-breakpoint-down(lg) {
      flex-direction: column;
    }
  }

  &:hover {
    .expertise-thumbnail {
      opacity: 0.8
    }
  }
}

.expertise-item-content-wrapper {
  display: flex;
  padding: 24px 48px;
  justify-content: center;
  align-items: center;
  flex: 1;
  background-color: #FFF;
}

.expertise-item-image {
  max-width: 500px;
  object-fit: cover;
  width: 100%;
  height: 100%;
  @include media-breakpoint-down(lg) {
    max-width: 100%;
  }
  @include media-breakpoint-down(md) {
    width: 100%;
  }
}


.card-body {
  font-size: 1em;
  padding-top: 72px;
  padding-bottom: 72px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background-color: transparent;
}

.content-width-small {
  width: 100%;
  max-width: 350px;
}

.content-width-medium {
  width: 100%;
  max-width: 500px;
  @include media-breakpoint-down(lg) {
    max-width: 650px;
  }
}

.team-member-grid {
  max-width: 1060px;
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  @include media-breakpoint-down(lg) {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    width: 100%;
  }
}

.team-member {
  &.team-member-reverse {
    @include media-breakpoint-down(lg) {
      flex-direction: column-reverse;
      align-items: center;
    }
  }

  @include media-breakpoint-down(lg) {
    background: var(--color-base-bg-2);
    flex-direction: column;
    align-items: center;
  }


  &:last-of-type {
    .team-member-content-wrapper {
      @include media-breakpoint-down(lg) {
        margin-right: 0;
      }
    }
  }

  .image-wrapper {
    img {
      @include media-breakpoint-up(lg) {
        max-width: 380px;
      }
    }
  }
}

.team-member-content-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 48px;
  margin-left: 12px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  @include media-breakpoint-down(lg) {
    margin-top: 0;
    padding: 36px 12px;
    margin-left: 0;
  }
}


.container.grid-container {
  @include media-breakpoint-down(lg) {
    max-width: 620px;
  }
}

.text-uppercase {
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.05em;
  text-indent: -0.05em;
}


.image-frame {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 550px;
  padding-top: 24px;
  padding-bottom: 24px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;

  &-small {
    min-height: 450px;
  }

  @include media-breakpoint-down(lg) {
    min-height: auto;
  }
}

.image-wrapper {
  position: relative;
}

.inline-title {
  color: var(--color-base-bg);
  font-size: 2em;
  text-shadow: 1px 1px 2px var(--color-secondary);
  @include media-breakpoint-down(lg) {
    font-size: 1.7em;
  }
}
