.button {
  white-space: nowrap;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding: 0 14px;
  //box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  background: var(--color-secondary);
  border-radius: 0;
  font-size: 14px;
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  color: var(--color-primary-text);
  text-decoration: none;
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  font-family: $font-family-heading;
}

.btn {
  border-radius: 0;
  &:hover {
    text-decoration: none;
  }
}
