.header {
  font-family: $font-family-heading;
  background-color: var(--color-base-bg);

  .container {

    .header-inside {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: $header-height;
      padding-top: 5px;
      padding-bottom: 5px;
      border-bottom: 2px solid var(--color-secondary);
    }

    .logos {
      margin-right: auto;
    }
  }
}

/*.header-transparent {
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 10;
  background-color: transparent !important;
  box-shadow: none !important;
  color: white;
  border-bottom: none !important;

  a {
    color: white;
  }

  .hamburger {
    color: white;
  }

  .logos {
    .logo {
      display: none;
      @include media-breakpoint-up(sm) {
        display: none;
      }
    }

    .logo-mobile {
      display: none;
      @include media-breakpoint-up(sm) {
        display: none;
      }
    }

    .logo-desktop-invert {
      display: none;
      @include media-breakpoint-up(sm) {
        display: block;
      }
    }

    .logo-mobile-invert {
      display: block;
      @include media-breakpoint-up(sm) {
        display: none;
      }
    }
  }
}*/


.header-scrolled, .header-transparent {
  transition: all 200ms linear;
  background-color: var(--color-base-bg);
  padding: 0;
  color: var(--color-primary);

  a {
    color: var(--color-primary);
  }

  .hamburger {
    color: var(--color-primary);
  }

  .menu-main {
    ul {
      align-items: center;
      flex-direction: row;
    }
  }
}

.header-fixed {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 10;
  transition: all 300ms ease-out;

}
