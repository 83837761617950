.card-competence {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0;
  background-color: var(--color-card-bg);
  overflow: hidden;
  position: relative;
  border-radius: $card-border-radius;

  .card-thumbnail {
    border-radius: $card-border-radius;
    overflow: hidden;

    a {
      display: block;
    }

    img {
      aspect-ratio: 16 / 9;
      width: 100%;
      object-fit: cover;

      &:hover {
        opacity: 0.8;
      }
    }

    &.card-thumbnail-large {
      @include media-breakpoint-up(md) {
        max-height: 260px;
      }
      @include media-breakpoint-up(xl) {
        max-height: 300px;
      }
    }
  }

  .card-title {
    h3 {
      font-weight: 500;
      font-size: 20px;
      letter-spacing: -0.2px;
    }

    a {
      color: var(--color-base-text);
    }
  }

  .card-description {
    margin: 10px 0 10px 0;

    p {
      line-height: 1.3;
      color: var(--color-base-text-2);
      margin: 0;
      font-weight: normal;
    }
  }

  .card-title + .card-description {
    margin-top: 0;
  }

  .card-job {
    font-weight: 300;
    font-size: 15px;
    color: var(--color-base-text-2);
  }

  .card-content {
    padding-top: 6px;
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  .card-readmore {
    margin-top: auto;
    line-height: 1;

    i {
      margin-left: 5px;
      margin-top: 4px;
    }
  }

  .card-date {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: normal;
    color: var(--color-base-text-2);
    font-weight: normal;
    font-size: 16px;
  }

  .card-categories {
    display: inline-flex;
    font-size: 12px;
    font-family: $font-family-monospace;

    > a {
      background-color: var(--color-base-bg-2);
      padding: 3px 7px;
      border-radius: 2px;
      margin-right: 10px;
    }
  }

  // Tweaks for dark mode
  html[data-mode="dark"] & {
  }
}
