.heading {
  .heading-heading {
    h1 {
      font-size: 42px;
      line-height: 1.1;
      letter-spacing: 0;
      font-family: $font-family-heading;
      strong {
        font-style: normal;
        text-decoration-line: underline;
        text-decoration-color: antiquewhite;
      }
    }
  }
  .heading-description {
    width: 90%;
    p {
      font-size: 21px;
      line-height: 1.24;
      font-family: $font-family-base;
      color: var(--color-base-text-2);
      @include media-breakpoint-up(md) {
        font-size: 24px;
        letter-spacing: 0;
        line-height: 1.4;
      }
    }
  }
  .heading-heading + .heading-description {
    margin-top: 20px;
    @include media-breakpoint-up(md) {
      margin-top: 30px;
    }
  }
  .heading-badge {
    font-family: $font-family-monospace;
    color: var(--color-primary);
  }
  .heading-image {
    margin-top: 30px;
    img {
      max-width: 100%;
    }
  }
  .heading-video {
    margin-top: 30px;
    iframe {
      max-width: 100%;
    }
  }
}
