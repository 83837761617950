.footer {
  padding-top: calc($section-gutter * 1.5);
  font-family: $font-family-heading;

  .footer-info {
    h2,
    h3 {
      font-weight: 600;
      font-size: 23px;
      margin-bottom: 7px;
      color: var(--color-base-text-dark);
    }

    p {
      font-size: 16px;
      color: var(--color-base-text-2-dark);
    }
  }

  .social {
    margin-top: 20px;
    color: var(--color-base-text-2-dark);
  }

  .footer-heading {
    font-weight: 600;
    font-size: 17px;
    color: var(--color-base-text-dark);
    margin-bottom: 7px;
  }

  .menu-footer {
    font-size: 18px;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      align-items: center;

      li {
        margin: 0;
        padding: 0.2em 0.5em;

        a {
          display: block;
          color: var(--color-base-text-dark);
          text-decoration: none;
          padding: 4px 0 4px 0;
          @include media-breakpoint-up(md) {
            padding: 2px 0 2px 0;
          }

          &:hover {
            text-decoration: underline;
          }
        }
      }

      li.active {
        a {
          font-weight: 600;
        }
      }
    }
  }

  .bottom {
    display: flex;
    justify-content: center;
  }
}
